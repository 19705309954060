import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  ComponentPreview,
  DontDo,
  Link,
  List,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  PlatformTable,
  Section,
  SectionSubhead,
} from '../../../components';
import V5Notice from '../v5-notice/V5Notice';
import actionListPreviewData from '../../../data/previews/actionlist.yml';
import pageHeroData from '../../../data/pages/components.yml';
import { IconAdd } from '@hudl/uniform-ui-components';

const ActionList = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.ActionList;
    return <Component {...props} />;
  },
});

const IconDelete = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.IconDelete;
    return <Component {...props} />;
  },
});

const IconShare = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.IconShare;
    return <Component {...props} />;
  },
});

const IconPlaylistAdd = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.IconPlaylistAdd;
    return <Component {...props} />;
  },
});

const IconEdit = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.IconEdit;
    return <Component {...props} />;
  },
});

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedType: 'label',
      selectedLabel: 'default',
    };
  }

  handleStyleChange = () => {
    this.setState({
      selectedType: this.state.selectedType === 'icon' ? 'label' : 'icon',
    });
  };

  handleLabelChange = value => {
    console.log(`handleLabelChange(${value})`);
    this.setState({
      selectedLabel: value,
    });
  };

  render() {
    const actions =
      this.state.selectedType === 'icon'
        ? [
          {
            text: 'Add to Playlist',
            icon: <IconPlaylistAdd />,
            onClick: () => {},
          },
          {
            text: 'Share',
            icon: <IconShare />,
            onClick: () => {},
          },
          {
            text: 'Edit',
            icon: <IconEdit />,
            onClick: () => {},
          },
          {
            text: 'Delete',
            icon: <IconDelete />,
            buttonType: 'destroy',
            onClick: () => {},
          },
        ]
        : [
          {
            text: 'Add to Playlist',
            onClick: () => {
              console.log('Added to Playlist');
            },
          },
          {
            text: 'Share',
            onClick: () => {
              console.log('Shared');
            },
          },
          {
            text: 'Edit',
            onClick: () => {
              console.log('Edited');
            },
          },
          {
            text: 'Delete',
            buttonType: 'destroy',
            onClick: () => {
              console.log('Deleted');
            },
          },
        ];

    return (
      <PageWithSubNav
        pageType="design"
        title="Action List"
        designCode
        subnav="components">
        <PageHero heroData={pageHeroData} tierTwo="Action List" />
        <V5Notice
          componentName="Action List"
          storybookUrl="https://uniform-web.storybook.hudltools.com/?path=/docs/general-action-list--docs"
        />

        <PageNavigation
          links={[
            'Type',
            'Size',
            'Label',
            'Style',
            'Mobile',
            'Usage',
            'Microcopy',
            'Accessibility',
            'Platform',
          ]}
        />

        <Section title="Type">
          <Paragraph>
            The action list type is determined both by the actions listed and
            the list’s relationship to nearby buttons.
          </Paragraph>
          <ComponentPreview
            name="ActionListType"
            previewData={actionListPreviewData.types}>
            <ActionList
              type="primary"
              actions={[
                {
                  text: 'Share',
                  onClick: () => {},
                },
                {
                  text: 'Edit',
                  onClick: () => {},
                },
                {
                  text: 'Delete',
                  buttonType: 'destroy',
                  onClick: () => {},
                },
              ]}
            />
          </ComponentPreview>
        </Section>

        <Section title="Size">
          <Paragraph>
            The action list can be used in all of our component sizes to best
            meet the needs of the interface it's used in.
          </Paragraph>
          <ComponentPreview
            name="ActionListSize"
            previewData={actionListPreviewData.sizes}>
            <ActionList
              size="small"
              actions={[
                {
                  text: 'Share',
                  onClick: () => {},
                },
                {
                  text: 'Edit',
                  onClick: () => {},
                },
                {
                  text: 'Delete',
                  buttonType: 'destroy',
                  onClick: () => {},
                },
              ]}
            />
          </ComponentPreview>
        </Section>

        <Section title="Label">
          <Paragraph>
            The action list button can either include an icon or text. The
            default label is the <code>IconUiMore</code>.
          </Paragraph>
          <ComponentPreview
            name="ActionListLabel"
            layout="default"
            previewData={actionListPreviewData.label}
            onChange={this.handleLabelChange.bind(this)}>
            {this.state.selectedLabel === 'default' && (
              <ActionList
                type="primary"
                actions={[
                  {
                    text: 'Share',
                    onClick: () => {},
                  },
                  {
                    text: 'Edit',
                    onClick: () => {},
                  },
                  {
                    text: 'Delete',
                    buttonType: 'destroy',
                    onClick: () => {},
                  },
                ]}
              />
            )}
            {this.state.selectedLabel === 'text' && (
              <ActionList
                type="primary"
                buttonLabel="Share to..."
                actions={[
                  {
                    text: 'Facebook',
                    onClick: () => {},
                  },
                  {
                    text: 'Twitter',
                    onClick: () => {},
                  },
                  {
                    text: 'Instagram',
                    onClick: () => {},
                  },
                  {
                    text: 'Copy link',
                    onClick: () => {},
                  },
                ]}
              />
            )}
            {this.state.selectedLabel === 'icon' && (
              <ActionList
                type="primary"
                buttonIcon={<IconAdd />}
                actions={[
                  {
                    text: 'Add Playlist',
                    onClick: () => {},
                  },
                  {
                    text: 'Add Video',
                    onClick: () => {},
                  },
                ]}
              />
            )}
          </ComponentPreview>
        </Section>

        <Section title="Style">
          <Paragraph>
            The style depends on the interface, not just in terms of available
            space, but also how nearby actions appear.
          </Paragraph>
          <ComponentPreview
            name="ActionListStyle"
            previewData={actionListPreviewData.style}
            onChange={this.handleStyleChange}>
            <ActionList type="primary" actions={actions} />
          </ComponentPreview>
        </Section>
        <Section title="Mobile">
          <Paragraph>
            Action lists are replaced by{' '}
            <Link href="https://developer.apple.com/design/human-interface-guidelines/ios/views/action-sheets/">
              action sheets
            </Link>{' '}
            (Apple) and{' '}
            <Link href="https://material.io/components/sheets-bottom/">
              sheets
            </Link>{' '}
            (Android). They should be used in all cases. Include potentially
            destructive, and cancel actions, if necessary.
          </Paragraph>
        </Section>
        <Section title="Usage">
          <SectionSubhead>Actions</SectionSubhead>
          <List>
            <li>
              Actions listed should apply to all selected items. Once a
              selection is made, the user cannot make exceptions as to which
              items are affected.
            </li>
            <li>
              There isn’t a limit to how many actions may be provided, but there
              is a minimum of two—including any destructive actions.
              <DontDo
                dontText="list a single action when one button will do."
                doText="list more than one action that applies to all selected items."
                imgFilename="actionlist-itemnumber"
              />
            </li>
            <li>
              Destructive actions should always be placed last in the list.
              Users can adapt to seeing items like delete near the end.
              <DontDo
                dontText="list actions in an illogical order."
                doText="keep destructive actions anchored to the bottom."
                imgFilename="actionlist-itemorder"
              />
            </li>
          </List>
          <SectionSubhead>Placement</SectionSubhead>
          <Paragraph>
            An action list should appear near the right side of an interface,
            especially when used inside of an{' '}
            <Link href="/components/action-bar" isDesignCodeLink>
              action bar
            </Link>
            .
          </Paragraph>
          <SectionSubhead>Type</SectionSubhead>
          <Paragraph>
            Because an action bar provides primary actions for the items
            selected,{' '}
            <strong>
              an action list used within any action bar should always be
              primary.
            </strong>
          </Paragraph>
          <DontDo
            dontText="use a secondary or subtle action list in an action bar."
            doText="include additional primary actions."
            imgFilename="actionlist-actionbar"
          />
        </Section>

        <Section title="Microcopy">
          <SectionSubhead>Actions</SectionSubhead>
          <Paragraph>
            <strong>Actions should always be verbs.</strong> If you can’t stick
            to a single word, max out with a three-word phrase.
          </Paragraph>
          <DontDo
            dontText="use a complete sentence for each action."
            doText="give them a single directive for all selected items."
            imgFilename="actionlist-action"
          />

          <SectionSubhead>Case &amp; Punctuation</SectionSubhead>
          <Paragraph>
            All item labels should follow our{' '}
            <Link href="http://hudl.com/design/writing/writing-for/teams/product">
              UI label guidelines
            </Link>
            . Use title case with no punctuation.
          </Paragraph>
          <DontDo
            dontText="use sentence case for an action."
            doText="title case the entire string."
            imgFilename="actionlist-sentence"
          />
        </Section>

        <AccessibilityAuditComponentResultsSection componentName="Action List" />

        <Section title="Platform">
          <PlatformTable />
        </Section>
      </PageWithSubNav>
    );
  }
}
